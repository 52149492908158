<template>
  <ion-page>
    <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col>

        <ion-button expand="block" :color="idx ? 'primary' : 'dark'" :disabled="!idx" @click="idx = idx ? idx-1 : idx">
          <!-- eslint-disable vue/no-deprecated-slot-attribute -->
          <ion-icon slot="icon-only" :icon="chevronBackCircleOutline"></ion-icon>
        </ion-button>


      </ion-col>
      <ion-col>
        <ion-button expand="block" :color="(idx+1>=videos.length) ? 'dark' : 'primary'" :disabled="(idx+1>=videos.length)" @click="idx = (idx+1>=videos.length) ? idx : idx+1">
          <!-- eslint-disable vue/no-deprecated-slot-attribute -->
          <ion-icon slot="icon-only" :icon="chevronForwardCircleOutline"></ion-icon>
        </ion-button>

      </ion-col>
      <ion-col class="ion-text-center">
        <ion-label style="color: white">
        Vídeo {{ idx+1 }} de {{ videos.length }}</ion-label>
      </ion-col>
      <ion-col>
        <ion-button expand="block" color="danger" @click="cerrarModal">
          <!-- eslint-disable vue/no-deprecated-slot-attribute -->
          <ion-icon slot="start" :icon="closeCircleOutline"></ion-icon>
          CERRAR
        </ion-button>
      </ion-col>

    </ion-row>
    <ion-row class="videoCol">
      <ion-col>
        <video controls :key="idx">
          <source :src="videos[idx].url">
        </video>
      </ion-col>
    </ion-row>
    

    </ion-grid>

  </ion-page>
</template>

<script lang="ts">
import { chevronBackCircleOutline, chevronForwardCircleOutline, closeCircleOutline } from 'ionicons/icons';
import ud from "@/modules/cyanUserData";
import { territorios } from "@/modules/cyanRegions";

import { defineComponent } from "vue";
import {
  IonPage,
  IonLabel,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  modalController
} from "@ionic/vue";

export default defineComponent({
  name: "ReportarIncidenciaStreamlined",
  components: {
    IonButton,
    IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
    IonIcon,
  },
  setup() {
    return {
      closeCircleOutline, chevronBackCircleOutline, chevronForwardCircleOutline
    }
  },
  data() {
    return {
      idx: 0
    };
  },
  computed: {

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    tiposIncidencia(): any {
      return ud('tiposIncidencia', {'0':'Error'});
    },
  },
  methods: {
    cerrarModal: modalController.dismiss,
  },
  props: ['videos']
});
</script>

<style scoped>
video, ion-grid {
  width: 100%;
}
.ion-page {
  background: black;
}
.videoCol {
  flex: 1 0 auto;
  justify-items: center;
}

.videoCol ion-col { display: flex; }

ion-grid {
  display: flex;
  flex-direction: column;
}
</style>