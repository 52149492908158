<template>
  <ion-page>
    <cyan-page-wrapper
      title="Reportar incidencia"
      nav1="REPORTAR INCIDENCIA"
      :nav2="datosCentro.nombre"
      :ambito="ambito"
      :isModal="true"
      class="reportarIncidencia"
    >
      <div class="banner nivel1 hdr">DATOS DE LA INCIDENCIA A REPORTAR</div>

      <div class="banner nivel2">Tipo de incidencia</div>

      <div expand="block" :class="['tipoIncidencia', tiposIncidencia[tipoIncidencia] ? '' : 'placeholder']" @click="abrirModal"> {{ tiposIncidencia[tipoIncidencia] ?? 'Pulse para elegir un tipo...'}}</div>

      <div class="banner nivel2">Comentarios (opcional)</div>
      <ion-textarea v-model="comentarios"></ion-textarea>

      <ion-button expand="block" @click="enviarIncidencia">
        <!-- eslint-disable vue/no-deprecated-slot-attribute -->
        <ion-icon slot="start" :icon="alertCircleOutline"></ion-icon>
        <!-- eslint-enable vue/no-deprecated-slot-attribute -->

        ENVIAR INCIDENCIA</ion-button
      >
      <ion-button expand="block" @click="cerrarModal" color="secondary">
        <!-- eslint-disable vue/no-deprecated-slot-attribute -->
        <ion-icon slot="start" :icon="closeCircleOutline"></ion-icon>
        <!-- eslint-enable vue/no-deprecated-slot-attribute -->

        CANCELAR</ion-button
      >
    </cyan-page-wrapper>
  </ion-page>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";
import { alertCircleOutline, closeCircleOutline } from 'ionicons/icons';
import ud from "@/modules/cyanUserData";
import { territorios } from "@/modules/cyanRegions";

import { defineComponent } from "vue";
import {
  IonPage,
  IonTextarea,
  IonButton,
  IonIcon,
  modalController
} from "@ionic/vue";
import CyanPageWrapper from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import ModalSelectorTipoIncidencia from "./ModalSelectorTipoIncidencia.vue";

export default defineComponent({
  name: "ReportarIncidenciaStreamlined",
  components: {
    IonTextarea,
    IonButton,
    IonPage,
    IonIcon,
    CyanPageWrapper,
  },
  setup() {
    return {
      alertCircleOutline,closeCircleOutline
    }
  },
  data() {
    return {
      tipoIncidencia: '',
      comentarios: ''
    };
  },
  computed: {

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    tiposIncidencia(): any {
      return ud('tiposIncidencia', {'0':'Error'});
    },
  },
  methods: {
    async abrirModal() {
      const _t = this as any;
      const modal = await modalController.create({
        component: ModalSelectorTipoIncidencia,
      });
      modal.onWillDismiss().then(function(d) {
        if (typeof _t.tiposIncidencia[(d.data as any)??'0'] !== 'undefined')
          _t.tipoIncidencia = d.data;
      });
      return modal.present();
    },

    cerrarModal: modalController.dismiss,
    enviarIncidencia: async function() {
      await cyanRequest('jrsl/addIncidencia', {
          needsAuth: true,
          isPost: true,
          isModal: true,
          retries: 3,
          params: {
            'tipo_incidencia': this.tipoIncidencia,
            'descripcion': this.comentarios
          }
      }).then((d) => {
        if (d.ok) {
          store.commit('patchUserData', d);
          modalController.dismiss();
        } 
      });
    }
  },
});
</script>

<style scoped>
.hdr {
  margin-bottom: 1em;
}

.tipoIncidencia {

  margin: 1em 0;
  padding: 0.5em;
  text-align: center;
  cursor: pointer;
  
}

.tipoIncidencia.placeholder {
  color: #666;

}
</style>