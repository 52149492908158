<template>
  <ion-page>


    <cyan-page-wrapper
      title="Incidencias"
      backRoute="/4F"
      nav1="INCIDENCIAS"
      :nav2="datosCentro.nombre"
      :ambito="ambito"
      :iccFlex="true"
    >


      <div class="dummyDiv"></div>

        <div class="mobileShow">
          <div class="banner nivel1">{{ datosCentro.nombre }}</div>
          <div class="banner nivel2">{{ ambito }}</div>
        </div>
        <div>
        <ion-button expand="block" @click="nuevaIncidencia">
          <!-- eslint-disable vue/no-deprecated-slot-attribute -->
          <ion-icon slot="start" :icon="alertCircleOutline"></ion-icon>

          REPORTAR INCIDENCIA</ion-button
        >

        <ion-button expand="block" :color="completo ? 'secondary' : 'success'" @click="completo = !completo" v-if="numIncidenciasCerradas">

          <ion-icon slot="start"  :icon="checkmarkCircleOutline"></ion-icon>

          {{ completo ? 'OCULTAR' : 'MOSTRAR' }} {{ numIncidenciasCerradas }} INCIDENCIA{{ numIncidenciasCerradas==1 ? '' : 'S'}} CERRADA{{ numIncidenciasCerradas==1 ? '' : 'S'}}</ion-button
        >
        </div>
        <div class="lista-incidencias" v-if="incidenciasSeleccionadas && incidenciasSeleccionadas.length">
          <div class="banner">
            INCIDENCIAS {{ completo ? 'REPORTADAS' : 'ABIERTAS'}} ({{ incidenciasSeleccionadas.length }})
          </div>
          <ion-list>
            <transition-group name="cyanfade" mode="out-in">
            <ion-item
              v-for="i in incidenciasSeleccionadas"
              :key="i.id"
              class="incidencia"
            >
              <ion-grid>
                <ion-row>
                  <ion-col size="12" class="nombre">{{
                    i.tipo_incidencia
                  }}</ion-col>
                </ion-row>
                <ion-row v-if="i.descripcion">
                  <ion-col>
                    {{ i.descripcion }}
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12" size-md class="dato">
                    ID:
                    <span>#{{ i.id }}</span>
                  </ion-col>
                  <ion-col size="12" size-md class="dato">
                    Estado:
                    <span>{{ i.estado_incidencia }}</span>
                    <ion-icon :icon="iconosEstado[i.estado_incidencia_id]" :color="coloresEstado[i.estado_incidencia_id]" class="iconoEstado"></ion-icon>
                  </ion-col>

                  <ion-col size="12" size-md class="dato">
                    Fecha/hora:
                    <span>{{
                      i.timestamp.substr(8, 2) +
                      "/" +
                      i.timestamp.substr(5, 2) +
                      " " +
                      i.timestamp.substr(11, 5)
                    }}</span>
                  </ion-col>
                </ion-row>
                <ion-row v-if="i.estado_incidencia_id == 1">
                  <ion-col size="12" size-sm>
                    <ion-button expand="block" @click="enviarFoto(i.id)">
                      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                      <ion-icon slot="start" :icon="attach"></ion-icon>
                      ENVIAR IMAGEN
                    </ion-button>
                  </ion-col>
                  <ion-col size="12" size-sm>
                    <ion-button expand="block" @click="enviarFoto(i.id, true)">
                      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                      <ion-icon slot="start" :icon="videocamOutline"></ion-icon>
                      ENVIAR VÍDEO
                    </ion-button>
                  </ion-col>

                </ion-row>
                <ion-row>
                  <ion-col size="12" size-sm v-if="fotos[i.id].length">
                    <ion-button expand="block" @click="verFotos(i)">
                      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                      <ion-icon slot="start" :icon="imagesOutline"></ion-icon>

                      VER {{ fotos[i.id].length }}
                      {{ fotos[i.id].length == 1 ? "IMAGEN" : "IMÁGENES" }}
                    </ion-button>
                  </ion-col>
                  <ion-col size="12" size-sm v-if="videos[i.id].length">
                    <ion-button expand="block" @click="verVideos(i)">
                      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                      <ion-icon slot="start" :icon="filmOutline"></ion-icon>

                      VER {{ videos[i.id].length }}
                      {{ videos[i.id].length == 1 ? "VÍDEO" : "VÍDEOS" }}
                    </ion-button>
                  </ion-col>
                  <ion-col size="12" size-sm v-if="i.estado_incidencia_id == 1">
                    <ion-button
                      expand="block"
                      color="success"
                      @click="marcarResuelta(i.id)"
                    >
                      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
                      <ion-icon
                        slot="start"
                        :icon="checkmarkCircleOutline"
                      ></ion-icon>

                      MARCAR RESUELTA
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
            </transition-group>
          </ion-list>
        </div>
        <div class="banner nodata" v-else>No se han reportado incidencias.</div>

      <div class="dummyDiv"></div>
    </cyan-page-wrapper>
  </ion-page>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";
import CyanPageWrapper from '@/components/CyanPageWrapper.vue';
import ModalVideo from '@/views/ModalVideo.vue';
import { defineComponent } from "vue";
import {
  IonPage,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  alertController,
} from "@ionic/vue";

import {
  attach,
  imagesOutline,
  filmOutline,
  videocamOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
  banOutline,
  searchCircleOutline,
  mailUnreadOutline,
} from "ionicons/icons";

import ReportarIncidenciaStreamlined from "@/views/ReportarIncidenciaStreamlined.vue";
import { territorios } from "@/modules/cyanRegions";
import cyanRequest from "@/modules/cyanRequest";
import ud from "@/modules/cyanUserData";

import cyanGetPhoto from '@/modules/cyanGetPhoto'

export default defineComponent({
  name: "IncidenciasStreamlined",
  components: {
    IonPage,
    IonButton,
    IonList,
    IonIcon,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    CyanPageWrapper
  },
  setup() {

    const iconosEstado = {
      '1': mailUnreadOutline,
      '2': searchCircleOutline,
      '3': banOutline,
      '4': checkmarkCircleOutline
    };

    const coloresEstado = {
      '1': 'secondary',
      '2': 'warning',
      '3': 'danger',
      '4': 'success'
    };

    return {
      attach,
      iconosEstado,
      coloresEstado,
      imagesOutline,
      filmOutline,
      videocamOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
    };
  },
  data() {
    return {
      completo: false
    };
  },
  computed: {
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },

    incidencias(): any {
      return ud('incidencias',[]);
    },
    incidenciasAbiertas(): any {
      return this.incidencias.filter(function(x: any) { return x.estado_incidencia_id<3;});
    },
    numIncidenciasCerradas(): number {
      return this.incidencias.length - this.incidenciasAbiertas.length;
    },
    incidenciasSeleccionadas(): any {
      return this.completo ? this.incidencias : this.incidenciasAbiertas;
    },
    fotos(): any {
      const obj = {} as any;
      for(const i in this.incidencias) {
        const medios = [];
        for (const j in this.incidencias[i].medios) {
          const m = this.incidencias[i].medios[j];
          if (m.tipo_archivo.split('/')[0] == 'image')
            medios.push(m);
        }
        obj[this.incidencias[i].id] = medios;
      }
      return obj;
    },
    videos(): any {
      const obj = {} as any;
      for(const i in this.incidencias) {
        const medios = [];
        for (const j in this.incidencias[i].medios) {
          const m = this.incidencias[i].medios[j];
          if (m.tipo_archivo.split('/')[0] == 'video')
            medios.push(m);
        }
        obj[this.incidencias[i].id] = medios;
      }
      return obj;
    },

  },
  methods: {
    async nuevaIncidencia() {
      const modal = await modalController.create({
        component: ReportarIncidenciaStreamlined,
        cssClass: "fullscreen",
      });
      return modal.present();
    },

    async marcarResuelta(idIncidencia: any) {
      const confirmPromise = (): Promise<boolean> => {
        return new Promise((resolve) => {
          alertController
            .create({
              message:
                "¿Está seguro de que quiere mandar esta incidencia como resuelta?",
              buttons: [
                {
                  text: "Sí",
                  handler: () => resolve(true),
                },
                {
                  text: "No",
                  role: "cancel",
                  handler: () => resolve(false),
                },
              ],
            })
            .then((alert) => alert.present());
        });
      };

      if (await confirmPromise()) {
        cyanRequest("jrsl/closeIncidencia/" + idIncidencia, {
          isModal: true,
          isPost: true,
          needsAuth: true,
          retries: 3
        }).then(function (d) {
          if (d.ok) {
            store.commit("patchUserData", d);
          }
        });
      }
    },

    async enviarFoto(idIncidencia: any, video?: any) {

      const image = await cyanGetPhoto(video);

      if (image === false) {
        return;
      }

      cyanRequest("jrsl/fotoIncidencia/" + idIncidencia, {
        isPost: true,
        retries: 3,
        files: { fichero: image },
        isModal: "Subiendo " + (video ? 'video' : 'foto') + "...",
        needsAuth: true,
      }).then(function (d) {
        if (d.ok) {
          store.commit("patchUserData", d);
        }
      });
    },
    verFotos(incidencia: any) {
      const result = [];
      if (this.fotos[incidencia.id].length) {
        let counter = 1;
        for (const i of this.fotos[incidencia.id]) {
          result.push({
            src: i.url,
            title: "Imagen " + counter++ + " de " + this.fotos[incidencia.id].length,
          });
        }
      }

      if (result.length) {
        store.commit("setLightboxImages", result);
        store.commit("setLightboxVisible", true);
      }
    },
    verVideos(incidencia: any) {
      if (this.videos[incidencia.id].length == 0 ) return;

      modalController.create({
        component: ModalVideo,
        componentProps: {
          videos: this.videos[incidencia.id]
        }
      }).then(function(m) {
        m.present();
      });

    },
  },
});
</script>

<style scoped>
.lista-incidencias {
  margin-top: 1em;
}
.nombre {
  font-weight: 500;
  font-size: 120%;
  color: var(--ion-color-primary);
}
.dato {
  color: var(--cyan-dark-grey);
  font-weight: 500;
  padding-left: 1.6em;
  text-indent: -1.6em;
}
.dato span {
  color: var(--ion-color-primary);
  font-weight: normal;
}
.incidencia {
  margin: 5px 0;
  border: 1px solid var(--cyan-light-grey);
  border-radius: 15px;
  background: white;
  padding: 0 5px;
}
.iconoEstado {
  font-size: 150%;
  position: relative;
  top: 5px;
  margin-left: 5px;
}
</style>